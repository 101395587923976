body {
  margin: 0;
  font-family: 'Roboto', 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
  /*padding-bottom: 48px;*/
  position: relative;
}
.Carousel-active-529 {
  color:#24c0dc !important;
}
/*@media only screen*/
/*and (min-device-width: 375px)*/
/*and (max-device-width: 667px) {*/
/*  body {*/
/*    min-height: calc(100vh + 64px);*/
/*    padding-bottom: 128px;*/
/*  }*/
/*}*/

.react-emoji-picker--wrapper.react-emoji-picker--wrapper__show {
  width: 350px;
}

@media screen
and (max-width: 480px) {
  body {
    min-height: calc(100vh + 64px);
    padding-bottom: 128px;
  }
}

#root {
  padding-top: 64px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

@keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

/*.OTPublisherContainer {*/
/*  display: flex;*/
/*  !*border: 1px solid red;*!*/
/*  padding: 16px;*/

/*}*/
#videoContainer  > * {
  transition-property: all;
  transition-duration: 0.5s;
  /*width: 0;*/
  /*height: 0;*/
  /*opacity: 0;*/
}

.OT_audio-only > .OT_widget-container > .OT_video-poster {
  display: none !important
}
/*#videoContainer > *.ot-layout {*/
/*  opacity: 1;*/
/*}*/

/*#videoContainer {*/
/*  margin-left: 5px;*/
/*  margin-right: 5px;*/
/*}*/

/*.OT_publisher, .OT_subscriber {*/
/*  border-radius: 8px;*/
/*  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.5);*/
/*  border: 2px solid rgba(0,0,0,0.2);*/
/*}*/

.ot-layout {
  width: 100% !important;
  height: 100% !important;
}

/* Set the background color of body to tan */
.show-sm {
  display: flex !important;
}
.show-lg {
  display: none !important;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (min-width: 960px) {
  .show-sm {
    display: none !important;
  }
  .show-lg {
    display: flex !important;
  }
  .main-content {
    min-height: 100vh;
    width: calc(100% - 60px);
    margin-left: 60px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #9BC90D 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #9BC90D;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #9BC90D;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #9BC90D;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #9BC90D;
}

.MuiTable-root .MuiTextField-root {
  border: none
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -20deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -20deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 10deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 10deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 15deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 15deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.react-input-emoji--input {
  outline: 1px solid transparent !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  ms-Word-Break: break-all;
  word-break: break-word;
  ms-Hyphens: auto;
  moz-Hyphens: auto;
  webkit-Hyphens: auto;
  hyphens: auto;
}

#anymeets-menu a {
  /*font-size: 20px;*/
  /*color: 'white';*/
  position: relative;
  /*text-transform: uppercase;*/
  text-decoration: none;
  padding-bottom: 8px;
}
#anymeets-menu a:before, #anymeets-menu a:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 2px;
}
#anymeets-menu a:before {
  opacity: 0;
  transform: translateY(-8px);
  transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
}
#anymeets-menu a:after {
  opacity: 0;
  transform: translateY(4px);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
}
#anymeets-menu a:hover:before, #anymeets-menu a:hover:after, #anymeets-menu a:focus:before, #anymeets-menu a:focus:after, #anymeets-menu a.active:after, #anymeets-menu a.active:before {
  opacity: 1;
  transform: translateY(0);
}
#anymeets-menu a:hover:before, #anymeets-menu a:focus:before {
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
}
#anymeets-menu a:hover:after, #anymeets-menu a:focus:after {
  transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s 0.2s;
}
